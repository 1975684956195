<template>
  <div class="text-center text-white mt-5 mb-5">
    <p>
      <a target="_blank" href="https://calendly.com/defi-options/30min">Calendly</a> |
      <a target="_blank" href="https://docs.defioptions.org/">Docs</a> |
      <a href="https://web.libera.chat/#DeFiOptionsDAO" target="_blank">IRC</a> |
      <a target="_blank" href="https://discord.gg/WCeKgHNz3z">Discord</a> |
      <a target="_blank" href="https://forum.defioptions.org/">Forum</a> |
      <a target="_blank" href="https://github.com/DeFiOptions">Github</a> |
      <a target="_blank" href="https://reddit.com/r/DeFiOptionsDAO/">Reddit</a> |
      <a target="_blank" href="https://t.me/DeFiOptionsDiscussions">Telegram</a> |
      <a target="_blank" href="https://twitter.com/DeFiOptionsDAO">Twitter</a>

    </p>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style>

</style>