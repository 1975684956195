<template>
  <div class="modal-body">
    Before I start using DeFiOptions, I confirm that:

    <ul>
      <li>
        I am aware that DeFi Options has been audited by PeckShield, thoroughly tested on the Kovan testnet, 
        but hasn't been battle tested in a live environment yet, so I will use it at my own risk.
      </li>
      <li>
        I am aware of the 
        <a href="https://docs.defioptions.org/knowledge-base/risk-factors" target="_blank">risk factors</a> 
        related to options trading.
      </li>
      <li>
        I am <strong>not</strong> located, incorporated, or otherwise established in, or a 
        resident of the United States of America.
      </li>
    </ul>

    <p>If you cannot confirm all of the above, please leave this website.</p>
  </div>
</template>

<script>
export default {
  name: "ComplianceModalBody"
}
</script>

<style>

</style>